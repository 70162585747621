.site-footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000000;
    box-shadow: 0 0 0 100vmax #000000;
    clip-path: inset(0 -100vmax); 
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
  .footer-biggest-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 70px;
  }

  .footer-logo-container {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
  }

  .footer-links-container {
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    gap: 7px;
  }

  .footer-links-container > a {
    text-decoration: none;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;
  }

  .footer-product {
    text-decoration: none;
    color: #ffffff;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .footer-product-mini-text {
    text-decoration: none;
    color: #ACACAC;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .footer-product-mini-text-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .footer-logo-img {
    margin-left: -6px;
    margin-bottom: 4px;
    width:122px;
  }


  .footer-logo-text {
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
  
  .footer-address-text {
    display: flex;
    flex-direction: column;
    margin-top: 39px;
  }

  .footer-address-title-text {
     font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;
    color: #ffffff;
    margin: 0;
    padding: 0;
  }

  .footer-address-body-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1.1%;
    color: #808080;
    margin: 0;
    padding: 0;
  }
  .social-media-footer {
    display: flex;
    flex-direction: column;
  }

  .icons-and-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
    align-items: center;
  }

  .social-icons {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .social-icons a {
    margin: 0;
    margin-right: 5px;
    padding: 0;
  }

  .legal-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
    margin-left: auto;
    color: #D9D9D9;
    margin: 0;
    padding: 0;

  }

  .legal-links a{
    text-decoration: none;
    color: #D9D9D9;

    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #D9D9D9;
    width: 100%; 
    margin: 0;
    padding: 0;
  }

  .copyright-text {
    color: #D9D9D9;
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
    margin-bottom: 44px;
  }

  .site-footer-container > div:last-of-type {
    margin-top: auto;
  }

@media screen and (min-width: 700px) {
  .site-footer-container {
    padding-left: 6%;
    padding-right: 6%;
  }

  .footer-links-container-and-address {
    display: flex;
    flex-direction: row;
    gap: 55px;
  }

  .footer-logo-img {
    margin-left: -7px;
    width: 156px;
  } 

  .footer-logo-text {
    font-size: 16px;  
  }

  .footer-links-container {
    margin-top: 0px;
    gap: 9px;
  }

  .footer-links-container-and-address {
    margin-top: 60px;
  }

  .footer-links-container > a {
    font-size: 14px;
  }

  .footer-address-text {
    margin-top: 0px;
  }

  .footer-address-title-text {
    margin-bottom: 4px;
  }

  .footer-address-body-text {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .footer-logo-container {
    margin-top: 45px;
  }

  .legal-links a{
    font-size: 12px;
  }

  .copyright-text {
    font-size: 12px;
    margin-bottom: 82px;
    margin-top: 21px;
  }

  .icons-and-links {
    margin-bottom: 16px;
  }

  .footer-product {
    font-size: 14px;
  }

}

@media screen and (min-width: 1000px) {
  .site-footer-container {
    padding-left: 11%;
    padding-right: 11%;
  }
}
  /*

  .footer-logo {
    max-width: 40px;
    margin-top: 30px;
    margin-bottom: 6px;
  }

  .footer-biggest-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;

    margin-bottom: 30px;

    width: 100%;
  }

  .footer-links-small-group {
    display: flex;
    flex-direction: row;
    
    gap: 45px;
  }

  .footer-column h4 {
    color: #000000;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
    padding: 0;
    
    margin-bottom: 7px;
  }

  .footer-column a {
    color: #000000;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    text-decoration: none;

    margin: 0;
    padding: 0;
  }

  .footer-column p {
    text-decoration: none;
    color: #808080;
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
    padding: 0;

    margin-top: -9px;

      cursor: pointer;
  }

  .footer-logo-container p {
    font-size: 10px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
    padding: 0;

    margin-top: -10px;
  }

  .social-media-footer {
    display: flex;
    flex-direction: column;
  }

  .icons-and-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .social-icons {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .social-icons a {
    color: #000;
    font-size: 21px;
    margin: 0;
    margin-right: 5px;
    padding: 0;
  }

  .legal-links {
    margin-left: auto;
    color: #D9D9D9;
    margin: 0;
    padding: 0;

  }

  .legal-links a{
    text-decoration: none;
    color: #D9D9D9;

    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #000000; 
    width: 100%; 
    margin: 0;
    padding: 0;
  }

  .copyright-text {
    color: #D9D9D9;
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1.1%;

    margin: 0;
    margin-bottom: 27px;
  }

  .site-footer-container > div:last-of-type {
    margin-top: auto;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  
  @media screen and (min-width: 480px) {
    .footer-links-small-group {
      gap: 20%;
    }
  }

  @media screen and (min-width: 640px) {
    .footer-links-small-group {
      gap: 25%;
    }
  }

  @media screen and (min-width: 700px) {

    .footer-biggest-group {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 90px;

      margin-bottom: 70px;
      margin-top: 80px;
    }

    .footer-links-small-group {
      gap: 31px;
    }

    .footer-column {
      gap: 12px;
      margin-top: 5px;
    }

    .footer-column h4 {
      font-size: 16px;
    }
  
    .footer-column a {
      font-size: 16px;
    }
  
    .footer-column p {
      font-size: 14px;
      margin-top: -14px;
    }
  
    .footer-logo-container p {
      font-size: 14px;
    }

    .footer-logo {
      max-width: 200px;
      margin-top: 0px;
      margin-bottom: 6px;
    }

    .legal-links a{
      font-size: 14px;
    }

    .copyright-text {
      font-size: 12px;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 1000px) {  
    .footer-logo-container p {
      font-size: 16px;
    }

    .footer-biggest-group {
      gap: 200px;
    }

    .footer-links-small-group {
      gap: 50px;
    }
  }

  @media screen and (min-width: 1300px) {
    .footer-biggest-group {
      gap: 350px;
    }

    .footer-links-small-group {
      gap: 100px;
    }
  }

  */