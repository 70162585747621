.all-posts-page-entire {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 54px;

  margin-left: 7.5%;
  margin-right: 7.5%;
}

.all-posts-page-entire-top-text {
  color: #979797;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1.1%;
  margin: 0;
  padding: 0;

  margin-bottom: 28px;
}

.all-posts-page-entire-title {
  color: #161616;
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1.1%;
  margin: 0;
  padding: 0;

  margin-bottom: 28px;
}

.all-posts-page-entire-body-text {
  color: #161616;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -1.1%;
  margin: 0;
  padding: 0;

  margin-bottom: 28px;
}

.all-posts-page-entire-blog-article-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 54px;
}

.all-posts-page-entire-blog-article-buttons button {
  border: 1px solid #ACACAC; /* Adjust to match your theme */
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  width: 134px;
  height: 48px;

  padding: 14px 23.2px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
}

.post_button_active {
  background-color: #787878;
  color: #ffffff;
}

.post_button_unactive {
  background-color: transparent;
  color: #161616;
}

.all-posts-blog-posts-image {
  border-radius: 10px; 
  width: 100%;
  object-fit: cover;
  height: 280px;
}

.all-posts-blog-posts-readmore {
  color: #161616;

  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1.1%;

  text-align: left;

  /* make button look like a link with underline */
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;

  /* shift button all the way to the left */
  padding: 0;
  margin: 0;

  float: left;

  margin-bottom: 28px;
}

.all-posts-blog-posts-title {
  color: #161616;

  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: -1.1%;

  text-align: left;

  margin: 0;
  padding: 0;

  margin-top: 28px;
  margin-bottom: 18px;
}

.all-posts-blog-posts-shorttext {
  color: #161616;

  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -1.1%;

  text-align: left;

  margin: 0;
  padding: 0;

  margin-bottom: 18px;
}

.all-posts-blog-post-button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
}

.all-posts-loaded-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.all-posts-blog-post {
  width: 100%;
}

.all-posts-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 54px;
}


.all-posts-button-number.active {
  border: 1px solid #787878;
}

.all-posts-button-change {
  border: none;
  color: #000000;
  background: #D9D9D9;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 10.5px 20px;
}

.all-posts-button-number {
  border: none;
  color: #000000;
  background: transparent;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 10px 14px;

  margin-left: 4px;
  margin-right: 3px;
}

.all-posts-button-number.active {
  border: 1px solid #787878;
}

  .all-posts-button-change.disabled, .all-posts-button-number.disabled {
  /* make it grayed out */
  color: #787878;
  cursor: not-allowed;
  }

  .all-posts-page-change-button-row {
    margin-bottom: 54px;
  }

  
@media screen and (min-width: 700px) {
  .all-posts-page-entire {  
    margin-left: 6%;
    margin-right: 6%;
  }

  .all-posts-blog-posts-image {
    height: 345px;
    /* make image as wide as the container but not stretch, just zoom in on image */
    object-fit: cover;

  }

  .all-posts-page-entire-top-text {
    margin-bottom: 36px;
  }

  .all-posts-blog-posts-readmore {
    margin-bottom: 64px;
  }
}

@media screen and (min-width: 1000px) {
  .all-posts-page-entire {  
    margin-left: 11%;
    margin-right: 11%;
  }
}

@media screen and (min-width: 1200px) {
  .all-posts-loaded-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;

    flex-wrap: wrap;

    gap: 8px;

    margin-top: 52px;
  }
  .all-posts-blog-posts-image {
    width: 345px;
  }

  .all-posts-blog-post {
    width: 345px;
  }
}