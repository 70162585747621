.all-products-page-entire {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
  
  .all-products-page-entire-top-text {
    color: #979797;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -1.1%;
    margin: 0;
    padding: 0;
  
    margin-bottom: 28px;
  }
  
  .all-products-page-entire-title {
    color: #161616;
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -1.1%;
    margin: 0;
    padding: 0;
  
    margin-bottom: 28px;
  }
  
  .all-products-page-entire-body-text {
    color: #161616;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -1.1%;
    margin: 0;
    padding: 0;
  
    margin-bottom: 28px;
    max-width: 1000px;
  }
  
  .all-products-button-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }

  .all-products-button-row-mini {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
  }

  .all-products-button-row-mini .product_type_button_mini_unactive {
    border: 1px solid #DCDCDC; /* Adjust to match your theme */
    background-color: #DCDCDC;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s;
  
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1.1%;
  

    width: 78px;
    height: 32px;
    

    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25);
  }

  .all-products-button-row-mini .product_type_button_larger_active {
    border: 1px solid #FFFFFF;
    background-color: #FFFFFF;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;
  
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1.1%;

    width: 141px;
    height: 48px;

    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25);
  }

  .product_type_button_arrow_mini {
    cursor: pointer;

    margin: 0;
    padding: 0;
  }
  
  .all-products-button-row button {
    border: 1px solid #ACACAC; /* Adjust to match your theme */
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;
  
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;
  
    width: 134px;
    height: 48px;
  
    padding: 0px 15px;

    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25);
  }
  
  .product_type_button_active {
    background-color: #787878;
    color: #ffffff;
  }
  
  .product_type_button_unactive {
    background-color: transparent;
    color: #161616;
  }
  
  .all-products-product-learnmore-button {
    color: #787878;
  
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -1.1%;
  
    /* make button look like a link with underline */
    background-color: transparent;
    border: 1px solid #ACACAC;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
  
    /* shift button all the way to the left */
    margin: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 16px;
    padding-right: 16px;
  
    margin-bottom: 22px;
  }
  
  .all-products-product-title {
    color: #161616;
    max-width: 230px;
  
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -1.1%;

    margin: 0;
    padding: 0;

    margin-top: 18px;
    margin-bottom: 18px;

    text-align: center;
  }
  
  .all-products-products-container-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
  }
  
  .all-products-loaded-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 28px;
  }
  
  .all-products-button-change {
    border: none;
  color: #000000;
  background: #D9D9D9;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 10.5px 20px;
  }
  
  .all-products-button-number {
    border: none;
  color: #000000;
  background: transparent;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 10px 14px;

  margin-left: 4px;
  margin-right: 3px;
  }
  
  .all-products-button-number.active {
    border: 1px solid #787878;
  }

    .all-products-button-change.disabled, .all-products-button-number.disabled {
    /* make it grayed out */
    color: #787878;
    cursor: not-allowed;
    }
  

  .all-products-product {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 340px;
    height: 340px;

    border-radius: 10px;
  }

  .all-products-product:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  }

  .all-products-loaded-container {
    margin-bottom: 28px;
  }

  .all-products-page-change-button-row {
    margin-bottom: 54px;
  }

  .all-products-product-image {
    padding-top: 5px;
    height: 150px;
    object-fit: cover;
  }

  .all-products_hero_img_stretched_container {
    width: 100vw; /* Stretch container to the full viewport width */
  height: 600px; /* Set the desired height for the image */
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  left: 50%; /* Center the container */
  right: 50%;
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  display: flex; /* Use flex to center the image inside the container */
  justify-content: center;
  align-items: center;
  }
  
  .all-products_hero_img_stretched {
    width: 100%; /* Make the image at least as wide as the container */
    height: 100%; /* Make the image at least as tall as the container */
    object-fit: cover; /* Cover the container, cropping the image as necessary */
    object-position: center; /* Center the image within the container */
    }

  
  
  
  
  @media screen and (min-width: 700px) {
    .all-products-page-entire {
      margin-left: 6%;
      margin-right: 6%;
    }

    .all-products-page-entire-body-text {
      max-width: 600px;
    }

    .all-products-loaded-container {
        flex-direction: row;
        align-items: baseline;
        flex-wrap: wrap;

        margin-bottom: 40px;
        gap: 15px;
    }

    .all-products-button-row {
        flex-wrap: wrap;
        max-width: 600px;
      }
  }
  
  @media screen and (min-width: 1000px) {
    .all-products-page-entire {
      margin-left: 11%;
      margin-right: 11%;
    }

    .all-products-page-entire-body-text {
      max-width: 1000px;
    }

    .all-products-button-row {
      max-width: 1000px;
    }
  }
  
  @media screen and (min-width: 1200px) {

  }