.experiencehub_hero_img_div {
    position: relative;
    min-width: 400px;
}

.experiencehub_hero_img_stretched_container {
    width: 100vw; /* Stretch container to the full viewport width */
  height: 600px; /* Set the desired height for the image */
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  left: 50%; /* Center the container */
  right: 50%;
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  }
  
  .experiencehub_hero_img_stretched {
    width: 100%; /* Make the image at least as wide as the container */
    height: 100%; /* Make the image at least as tall as the container */
    object-fit: cover; /* Cover the container, cropping the image as necessary */
    object-position: left; /* Center the image within the container */
    }

  
.experiencehub_hero_text {
    position: absolute;
    top: 100px;
    text-align: left;
    margin-left: 7.5%;
    margin-right: 7.5%;
}

.experiencehub_hero_text_title {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 36px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1.1%;
}

.experiencehub_hero_text_body {
    margin: 0;
    padding: 0;
    margin-top: 28px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1.1%;
}

.experiencehub_hero_button {
    border-radius: 10px;
    border: 1px solid #ACACAC;
    position: absolute;
    bottom: 54px;
    width: 355px;

    /* make it centered */
    left: 50%;
    transform: translateX(-50%);
}

.experiencehub_hero_button button {
    border: none;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;

    padding: 14px 30px;
}

.experiencehub_body_text_button button:hover {
    background-color: gray;
  }

.experiencehub_body_text_button {
    text-align: center;
    margin-bottom: 54px;
}
.experiencehub_body_text_button button {
    border: 1px solid #ACACAC; /* Adjust to match your theme */
    color: #161616;
    background: transparent;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;

    padding: 14px 41px;
}


.experiencehub_body_text_div {
    text-align: left;
    margin-left: 7.5%;
    margin-right: 7.5%;
    margin-top: 54px;
}

.experiencehub_body_title {
    margin: 0;
    padding: 0;
    color: #161616;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: -1.1%;
}
.experiencehub_body_texts {
    margin-top: 41px;
    margin-bottom: 54px;
    gap: 28px;
    display: flex;
    flex-direction: column;
}

.experiencehub_body_text {
    margin: 0;
    padding: 0;
    color: #161616;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -1.1%;
}

.experiencehub_button_active {
    position: relative;
    background: #D9D9D9;
    transition: background-color 0.3s;
    color: #000000;
    z-index: 2;
}

.experiencehub_button_unactive {
    position: relative;
    background: transparent;
    transition: background-color 0.3s;
    color: #ffffff;
    z-index: 1;
}

.experiencehub_hero_button_left {
    margin-left: -15px;
}

@media screen and (min-width: 700px) {
    .experiencehub_hero_text {
        top: 110px;
        margin-left: 6%;
        margin-right: 6%;
    }
    .experiencehub_body_text_div {
        margin-left: 6%;
        margin-right: 6%;
    }

    .experiencehub_hero_text_title {
        font-size: 28px;
        line-height: 24px;
        margin-bottom: 41px;
    }


    .experiencehub_body_title {
        font-size: 28px;
        line-height: 24px;
    }

    .experiencehub_body_text {
        font-size: 18px;
        line-height: 27px;
    }

    .experiencehub_body_text_button button {
        padding: 14px 37.2px;
    }
}

@media screen and (min-width: 1000px) {

    .experiencehub_hero_img_stretched_container {
        height: 800px;
    }

    .experiencehub_hero_text {
        top: 230px;
        margin-left: 11%;
        margin-right: 11%;
    }
    
    .experiencehub_hero_text_title {
        font-size: 42px;
        margin-bottom: 28px;
        max-width: 683px;
    }

    .experiencehub_hero_text_body {
        font-size: 18px;
        max-width: 545px;
    }    

    .experiencehub_body_text_div {
        margin-left: 11%;
        margin-right: 11%;
    }
}

@media screen and (min-width: 1367px) {
    .experiencehub_hero_img_stretched_container {
        height: 1200px;
    }

    .experiencehub_body_text_div {
        max-width: 1366px;
    }
}