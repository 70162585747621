.ContactUs {
    scroll-behavior: smooth;
}

.contactus_form_map_div {
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin-top: 54px;
}

.contactus_form {
    margin-bottom: 54px;
}

.contactus_body_text {
    font-size: 14px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
}

.contactus_title_text{
    font-size: 34px;
    line-height: 38px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;

    margin-bottom: 46px;
}

.contactus_form_label{
    font-size: 16px;
    line-height: 24px;
    color: #787878;
    letter-spacing: -1,1px;
    font-weight: 500;
}

.contactus_form_div {
    display: flex;
    flex-direction: column;
}

.contactus_form_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
    gap: 10px;
}


.contactus_form_name, .contactus_form_email, .contactus_form_message {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.contactus_form_message {
    margin-bottom: 28px;
}

.contactus_form_input_small {
    height: 48px;
    border-radius: 10px;
    border: 1px solid #787878;
    
}

.contactus_form_input_large {
    height: 182px;
    border-radius: 10px;
    border: 1px solid #787878;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    resize: none;
}

.contactus_form_submit {
    color:#ffffff;
    background-color: #161616;
    border-radius: 10px;
    height: 48px;
    width: 129px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1,1px;
    text-align: center;
    border: none;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.contactus_form_whatsapp {
    color:#ffffff;
    background-color: #44CE52;
    border-radius: 10px;
    height: 48px;
    width: 180px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -1,1px;
    text-align: center;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.contactus_form_buttons {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.contactus_map {
    width: 100%;
}

.contactus_map_img {
    width: 100%;
    min-width: 340px;
    height: 100%;
    min-height: 350px;
    border-radius: 10px;
}

.contactus_map_texts {
    display: flex;
    flex-direction: row;
    gap: 75px;
    margin-top: 12px;
    margin-left: 15px;
}

.contactus_map_reach_out{
    display: flex;
    flex-direction: column;    
    margin: 0;
    padding: 0;
    gap: 10px;
}

.contactus_map_text_title {
    font-size: 14px;
    line-height: 24px;
    color: #787878;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
}

.contactus_map_text_body {
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
}

.contactus_map_social_media {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 170px;
}

.contactus_map_social_media_icons {
    display: flex;
    flex-direction: row;
    gap: 7.5px;
}

.contactus_map_social_media_icons > a {
    color: #161616;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -1,1px;
    font-weight: 500;
}

.contactus_faq_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin-top: 40px;
}

.contactus_faq_img {
    width:264px;
    height: 252px;
}

.contactus_faq_title_text {
    font-size: 34px;
    line-height: 48px;
    color: #000000;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-top: 38px;
    margin-bottom: 18px;
    text-align: center;
}

.contactus_faq_body_text {
    font-size: 16px;
    line-height: 24px;
    color: #787878;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
    margin-bottom: 18px;
}

.contactus_faq_question_div_full {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.2);
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-top: 8px;

    cursor: pointer;
}

.contactus_faq_question_div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.contactus_faq_question_title {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

.contactus_faq_question_body {
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    margin-top: 23px;
}

.contactus_faq_question_button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.contactus_faq_question_pluss, .contactus_faq_question_minus {
    color: #808080;
    margin: 0;
    padding: 0;
    text-align: center;
}

.contactus_visit_us_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin-top: 55px;
}

.contactus_visit_us_top_text {
    font-size: 14px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-bottom: 16px;
    text-align: center;
    
}

.contactus_visit_us_title_text {
    font-size: 34px;
    line-height: 48px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-bottom: 54px;
    text-align: center;
}

.contactus_visit_us_column {
    flex-direction: column;
    display: flex;
    align-items: center;
}

.contactus_visit_us_img {
    width: 340px;
    height: 340px;
    object-fit: cover;

    border-radius: 10px;
}

.contactus_visit_us_img_text {
    font-size: 12px;
    line-height: 18px;
    color: #787878;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-top: 20px;
    text-align: left;
}

.contactus_visit_us_get_there_top {
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-top: 54px;
    margin-bottom: 16px;
    text-align: left;
    align-self: baseline;
}

.contactus_visit_us_get_there_title {
    font-size: 42px;
    line-height: 48px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-bottom: 16px;
    text-align: left;
    align-self: baseline;
}

.contactus_visit_us_get_there_body {
    font-size: 16px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 400;
    margin:0;
    padding: 0;
    margin-bottom: 28px;
    text-align: left;
    align-self: baseline;
}

.contactus_visit_us_button {
    align-self: baseline;
    margin-bottom: 54px;
}

.contactus_visit_us_button button {
    border: 1px solid #ACACAC; /* Adjust to match your theme */
    color: #161616;
    background: transparent;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;

    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;

    padding: 14px 23.2px;
}

.contactus_visit_us_button button:hover {
    background-color: gray;
  }


.contactus_question_button {
    border: 1px solid #ACACAC; /* Adjust to match your theme */
    color: #161616;
    background: transparent;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;

    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;

    padding: 14px 23.2px;

    align-self: center;
}

.contactus_question_button:hover {
    background-color: gray;
}

.contactus_question_button_container {
   width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 54px;
    margin-bottom: 48px;
}

.contactus_calendar_area_title {
    font-size: 34px;
    line-height: 48px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-bottom: 16px;
    text-align: center;
    margin-left: 7.5%;
    margin-right: 7.5%;

    margin-top: 26px;
}

.contactus_calendar_area_body {
    font-size: 14px;
    line-height: 24px;
    color: #161616;
    letter-spacing: -1,1px;
    font-weight: 500;
    margin:0;
    padding: 0;
    margin-bottom: 28px;
    text-align: center;
    margin-left: 7.5%;
    margin-right: 7.5%;
}


@media screen and (min-width: 700px) {
    .contactus_form_map_div {
        padding-left: 6%;
        padding-right: 6%;
        
        margin-top: 90px;
    }

    .contactus_form {
        max-width: 480px;
    }

    .contactus_title_text {
        font-size: 42px;
        line-height: 48px;
        margin-top: 28px;
        margin-bottom: 27px;
    }

    .contactus_map_img {
        height: 350px;
    }

    .contactus_map_texts {
        gap: 300px;
        margin-top: 54px;
    }

    .contactus_map_reach_out {
        gap: 15px;
    }

    .contactus_map_social_media {
        gap: 15px;
    }

    .contactus_faq_div {
        margin-top: 90px;
        padding-left: 6%;
        padding-right: 6%;
    }

    .contactus_faq_img {
        width: 432px;
        height: 413px;
    }

    .contactus_faq_title_text {
        font-size: 42px;
        line-height: 48px;
        margin-top: 54px;
        margin-bottom: 35px;
    }

    .contactus_faq_body_text {
        margin-bottom: 15px;
    }

    .contactus_faq_question_div_full {
        margin-top: 25px;
    }

    .contactus_visit_us_title_text {
        font-size: 42px;
        line-height: 48px;
    }

    .contactus_visit_us_img {
        width: 705px;
        height: 400px;
    }

    .contactus_visit_us_get_there_body {
        margin-bottom: 24px;
    }

    .contactus_visit_us_img_text {
        margin-left: 45px;
        margin-right: 45px;
    }

    .contactus_visit_us_div {
        padding-left: 6%;
        padding-right: 6%;
        margin-top: 93px;
    }

    .contactus_calendar_area_title {
        font-size: 42px;
        line-height: 48px;
        margin-left: 6%;
        margin-right: 6%;
    }

    .contactus_calendar_area_body {
        margin-left: 6%;
        margin-right: 6%;
    }
}

@media screen and (min-width: 1000px) {
    .contactus_form_map_div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap:42px;
        padding-left: 11%;
        padding-right: 11%;
        margin-top: 144px;
    }

    .contactus_faq_div {
        display: flex;
        flex-direction: row;
        margin-top: -140px;
        gap: 53px;
        align-items: flex-start;
        padding-left: 11%;
        padding-right: 11%;
    }

    .contactus_visit_us_row {
        display: flex;
        flex-direction: row;
    }

    .contactus_visit_us_column {
        display: flex;
        flex-direction: column;
    }

    .contactus_map_img {
        width: 100%;
        height: 100%;
        max-height: 504px;
        max-width: 540px;
    }

    .contactus_map_texts {
        gap: 150px;
        margin-top: 35px;
    }

    .contactus_title_text {
        margin-top: 12px;
        min-width: 420px;
    }

    .contactus_form_input_large {
        height: 150px;
    }

    .contactus_form_message {
        margin-bottom: 60px
    }

    .contactus_form {
        margin-bottom: 0px;
    }

    .contactus_faq_title_text {
        margin-top: 0px;
        margin-bottom: 25px;
        text-align: left;
    }

    .contactus_faq_body_text {
        text-align: left;
    }

    .contactus_faq_img {
        margin-top: 54px;
    }

    .contactus_faq_question_div_full {
        padding-top: 9px;
        padding-bottom: 9px;
        margin-top: 23px;
    }

    .contactus_visit_us_row {
        gap: 42px;
        margin-bottom: 54px;
    }

    .contactus_visit_us_img {
        width: 614px;
        height: 404px;
    }

    .contactus_visit_us_img_text {
        margin-left: 35px;
        margin-right: 35px;
    }

    .contactus_visit_us_button {
        align-self: center;
    }

    .contactus_visit_us_div {
        padding-left: 11%;
        padding-right: 11%;

        margin-top: 190px;
    }

    .contactus_calendar_area_title {
        margin-left: 11%;
        margin-right: 11%;
    }

    .contactus_calendar_area_body {
        margin-left: 11%;
        margin-right: 11%;
    }
}