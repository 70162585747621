.cta-section {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-left: 7.5%;
  margin-right: 7.5%;
}

.cta-section-text {
  font-size: 22px;
  line-height: 33px;
  font-weight: 400;
  letter-spacing: -1.1%;
  text-align: center;

  color: #000000;

  margin: 0;
  padding: 0;

  margin-top: 31px;
  margin-bottom: 33px;
}

.cta-section-button {
  align-self: center;
  margin-bottom: 28px;
}

.cta-section-button button {
  border: 1px solid #acacac; /* Adjust to match your theme */
  color: #161616;
  background: transparent;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 14px 23.2px;
}

.cta-section-button button:hover {
  background-color: gray;
}

.cta-section-img-rounded {
  border-radius: 10px;
  width: 100%;
  height: 200px;
  object-fit: cover;
}

@media screen and (min-width: 700px) {
  .cta-section {
    width: 88%;

    margin-left: 6%;
    margin-right: 6%;
  }


  .cta-section-text {
    font-size: 32px;
    line-height: 52px;
  
    margin-top: 42px;
    margin-bottom: 16px;
  }

  .cta-section-button {
    margin-bottom: 38px;
  }

  .cta-section-img-rounded {
    height: 400px;
  }
}
@media screen and (min-width: 1000px) {
  .cta-section {
    width: 78%;
    margin-left: 11%;
    margin-right: 11%;
  }

  .cta-section-text {
    max-width: 941px;
    font-size: 42px;
  
    margin-top: 54px;
    margin-bottom: 28px;
  }

  .cta-section-button {
    margin-bottom: 54px;
  }

  .cta-section-img-rounded {
    height: 600px;
  }
}
