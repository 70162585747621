.news-article-image-container {
  width: 100vw; /* Stretch container to the full viewport width */
  height: 380px; /* Set the desired height for the image */
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  display: flex; /* Use flex to center the image inside the container */
  justify-content: center;
  align-items: center;
}

.news-article-image {
  width: 100%; /* Make the image at least as wide as the container */
  height: 100%; /* Make the image at least as tall as the container */
  object-fit: cover; /* Cover the container, cropping the image as necessary */
  object-position: center; /* Center the image within the container */
}

.news-entire-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.news-article-contents {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;

  margin-top: 38px;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.news-article-info-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  margin: 0;
  padding: 0;

  bottom: 0;

  gap: 22.5px;
  margin-bottom: 13px;
}

.news-article-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  margin: 0;
  padding: 0;
  width: max-content;
}

.news-article-info-toptext {
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
  letter-spacing: -1.1%;
  color: #ffffff;

  margin: 0;
  padding: 0;

  width: max-content;
}

.news-article-info-bottomtext {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -1.1%;
  color: #ffffff;

  margin: 0;
  padding: 0;
}

.news-summary {
  color: #161616;
  font-size: 22px;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;
}

.news-title {
  color: #161616;
  font-size: 36px;
  line-height: 54px;
  font-weight: 500;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;
  margin-bottom: 36px;
}

.news-text {
  color: #161616;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;
}

.news-image {
  width: 100%;
  height: 340px;
  object-fit: cover;
  object-position: center;
  border-radius: 30px;
}

.news-next-button-container {
  padding-top: 12px;
  margin-bottom: 66px;
  text-align: center;
}

.news-next-button button {
  border: 1px solid #acacac; /* Adjust to match your theme */
  color: #161616;
  background: transparent;
  cursor: pointer;
  border-radius: 10px; /* Rounded corners */
  transition: background-color 0.3s;

  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;

  padding: 14px 23.2px;
}

.news-next-button button:hover {
  background-color: gray;
}


@media only screen and (min-width: 700px) {
  .news-article-contents {
    margin-top: 44px;
    margin-left: 6%;
    margin-right: 6%;
  }

  .news-summary {
    line-height: 40px;
  }

  .news-title {
    line-height: 40px;
  }

  .news-text {
    line-height: 28px;
  }

  .news-image {
    height: 400px;
  }

  .news-next-button-container {
    margin-bottom: 85px;
  }
}

@media only screen and (min-width: 1000px) {
  .news-article-contents {
    margin-top: 50px;
    margin-left: 11%;
    margin-right: 11%;
  }

  .news-article-info-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin: 0;
    padding: 0;
    width: 100%;

    margin-right: 100px;

    gap: 46px;
  }

  .news-article-info-toptext {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    letter-spacing: -1.1%;
    color: #161616;
  
    margin: 0;
    padding: 0;
  }
  
  .news-article-info-bottomtext {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;
    color: #161616;
  
    margin: 0;
    padding: 0;
  }

  .news-next-button-container {
    margin-bottom: 105px;
    text-align: right;
  }
}

@media only screen and (min-width: 1367px) {
}
