.error-container {
    position: relative;
    overflow: hidden; /* Hide the overflow part */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 400px;
    width: 100%;
}

.error-container img {
    height: 360px;
    width: 100%;
    object-fit: cover;
}

.error-title-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #878787;
    font-size: 30px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;
}

.error-body-text {
    width: 73%;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #161616;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: -1.1%;
}


@media screen and (min-width: 700px) {
    .error-title-text {
        font-size: 48px;
        top: 70%;
    }
    
    .error-body-text {
        color: #161616;
        font-size: 18px;
        line-height: 24px;
        top: 88%;
    }

    .error-container img {
        height: 600px;
    }
}

@media screen and (min-width: 1000px) {
    .error-title-text {
        top: 75%;
    }

    .error-container img {
        height: 970px;

    }
}