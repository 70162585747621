/* Hero image at the top of the page: start*/

.aboutus_hero_img_div {
    width: 100vw; /* Stretch container to the full viewport width */
  height: 700px; /* Set the desired height for the image */
  position: relative; /* Relative positioning for the container */
  left: 50%; /* Center the container */
  right: 50%;
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  }
  
  .aboutus_hero_img  {
    width: 100%; /* Make the image at least as wide as the container */
    height: 100%; /* Make the image at least as tall as the container */
    object-fit: cover; /* Cover the container, cropping the image as necessary */
    object-position: center; /* Center the image within the container */

  }


  .aboutus_hero_img_div  > .aboutus_hero_text  {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  

  .aboutus_hero_img_div > .aboutus_hero_text p {
    margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 54px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -1.1%;
  }

  @media screen and (min-width: 700px) {
    .aboutus_hero_img_div {
      height: 700px;
    }  

    .aboutus_hero_img_div > .aboutus_hero_text p {
        text-align: left;
        padding-left: 6%;
        padding-right: 6%;
    }
  }

  @media screen and (min-width: 1000px) {  
    .aboutus_hero_img_div > .aboutus_hero_text p{
        padding-left: 11%;
        padding-right: 11%;
    }
  }

  @media screen and (min-width: 1367px) {
    .aboutus_hero_text {
        max-width: 1366px;
    }
    
  }

/* Hero image at the top of the page: end */

/* About us section with text: start */

.aboutus_body_text_div {
    padding-left: 7.5%;
    padding-right: 7.5%;
    margin-bottom: 54px;
}

.aboutus_body_top_text {
    font-size: 36px;
    font-weight: 500;
    line-height: 52px;
    letter-spacing: -1.1%;
    margin-top: 28px;
    margin-bottom: 0;
}

.aboutus_body_text_section {
    margin-top: 28px;
    margin-bottom: 0px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -1.1%;
}

.aboutus_body_img_section {
    width: 100%;
    height: 340px;
    max-width: 780px;
    object-fit: cover;

    margin-top: 28px;
    border-radius: 30px;
}

.aboutus_body_text_images_div {
    display: flex;
    flex-direction: column;
    margin-top: 54px;
}

.aboutus_body_img_section1 {
    border-radius: 10px;
    width: 60%;
    height: 150px;
    object-fit: cover;
    
    padding: 0;
    margin: 0;
}

.aboutus_body_img_section2 {
    border-radius: 10px;
    width: 60%;
    height: 150px;
    object-fit: cover;

    padding: 0;
    margin: 0;
    margin-top: -40px;
    align-self: flex-end;
}

.aboutus_body_text_images_text {
    color: #161616;
    font-size: 28px;
    font-weight: 400;
    line-height: 86px;
    letter-spacing: -1.1%;
    margin: 0;
    padding: 0;
    margin-top: -70px;
}


@media screen and (min-width: 700px) {
    .aboutus_body_text_div {
        padding-left: 6%;
        padding-right: 6%;
    }

    .aboutus_body_top_text {
        font-size: 42px;
        margin-top: 54px;
    }

    .aboutus_body_text_section {
        line-height: 28px;
        font-weight: 500;
    }

    .aboutus_body_img_section {
        width: 100%;
        height: 380px;

        margin-top: 54px;

        max-width: 1200px;

        border-radius: 30px;
    }

    .aboutus_img_text_div2 {
        padding-bottom: 26px;
    }

    .aboutus_body_text_images_text {
        font-size: 48px;
        margin-top: -90px;
    }

    .aboutus_body_img_section1 {
        height: 260px;
    }

    .aboutus_body_img_section2 {
        height: 260px;
    }
  }

  @media screen and (min-width: 1200px) {  
    .aboutus_body_text_div {
      padding-left: 11%;
      padding-right: 11%;
    }

    .aboutus_column_div {
        display: flex;
        justify-content: space-between;
    }

    .aboutus_column {
        max-width: 500px;
    }

    .aboutus_img_text_div1 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        flex-direction: row-reverse;
    }

    .aboutus_img_text_div2 {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .aboutus_body_img_section{ 
        width: 525.5px;
        height: 380px;
        max-width: 525.5px;

        object-fit: cover;
    }

    .aboutus_body_text_images_text {
        margin-top: -130px;
    }

    .aboutus_body_img_section2 {
        margin-top: -120px;
    }

    .aboutus_body_img_section2{
        margin-bottom: 54px;
    }
  }

  @media screen and (min-width: 1000px) {
    .aboutus_hero_img_div {
        height: 800px;
    }

    .aboutus_body_img_section1 {
        height: 380px;
    }

    .aboutus_body_img_section2 {
        height: 380px;
    }
  }

  @media screen and (min-width: 1600px) {
    .aboutus_hero_img_div {
        height: 1200px;
    }
  }