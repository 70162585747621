.review-carousel .review-item {
  border-radius: 10px;
  background-color: #D9D9D9;
  transition: transform 0.5s ease; /* Smooth transition for any transformations */
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
  min-height: 250px;

  display: flex;
  flex-direction: column;

}

.carousel .slide {
  margin-bottom: 40px;
}

.slide.selected .review-item{
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(20px);
}

.review-carousel {
  margin-bottom: 30px;
}

.review-text {
  color: #161616;

  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;

  margin-top: 23px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 16px;

  text-align: left;
}

.review-name {
  color: #161616;

  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;

  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 14px;

  margin-top: auto;
  align-self: baseline;
}

.review-shortDesc {
  color: #161616;

  font-size: 10px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -1.1%;

  margin: 0;
  padding: 0;

  margin-top: -24px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 14px;

  align-self: baseline;
}

.review-top-text {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: #000000;
  text-align: center;

  margin: 0;
  padding: 0;

  margin-left: 7.5%;
  margin-right: 7.5%;
  margin-bottom: 28px;
}

.review-carousel-arrows {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: right;

  margin-right: 7.5%;
  margin-bottom: 50px;
 
  gap: 5px;
}

.review-carousel-arrow-left, .review-carousel-arrow-right {
  font-size: 24px;
  color: #ACACAC;
  cursor: pointer;
}

@media screen and (min-width: 700px) {
  .review-top-text {
    font-size: 32px;
    line-height: 24px;

    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 100px;
  }

  .review-carousel-arrows {
    margin-right: 6%;
  }
}

@media screen and (min-width: 1000px) {
  .review-top-text {
    font-size: 42px;

    margin-left: 11%;
    margin-right: 11%;
    margin-bottom: 57px;
  }

  .review-carousel-arrows {
    margin-right: 11%;
     }
}