.solutions-entire {
    min-width: 400px;
    max-width: 1366px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.solutions_stretched_container {
    width: 100vw; /* Stretch container to the full viewport width */
  height: 480px; /* Set the desired height for the image */
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  left: 50%; /* Center the container */
  right: 50%;
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  display: flex; /* Use flex to center the image inside the container */
  justify-content: center;
  align-items: center;
  }
  
  .solutions_img_stretched {
    width: 100%; /* Make the image at least as wide as the container */
    height: 100%; /* Make the image at least as tall as the container */
    object-fit: cover; /* Cover the container, cropping the image as necessary */
    object-position: top; /* Center the image within the container */
    }

.solutions-video {
    width: 100%;
}

.solutions-top-image-container, .solutions-bottom-image-container {
    position: relative;
    width: 100%;
    height: 480px;    
}

.solutions-top-image-container {
    margin-top: -7px;
}

.solutions-top-image-top-text, .solutions-bottom-image-top-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    text-align: left;

    margin: 0;
    padding: 0;

    margin-top: 90px;
    margin-left: 7.5%;
    margin-right: 7.5%;
}

.solutions-top-image-title, .solutions-bottom-image-title {
    font-size: 42px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    text-align: left;

    margin: 0;
    padding: 0;

    margin-top: 28px;
    margin-left: 7.5%;
    margin-right: 7.5%;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.solutions-top-image-bottom-text, .solutions-bottom-image-bottom-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
    text-align: left;

    margin: 0;
    padding: 0;

    margin-top: 28px;
    margin-left: 7.5%;
    margin-right: 7.5%;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.solutions-icons-container-entire {
    height: 175px;
    width: 100%;
    margin-left: 7.5%;
    margin-right: 7.5%;
    z-index: 1;
}

.solutions-icons-container {
    height: 180px;
    border-radius: 10px;
    background-color: #ffffff;

    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 40px;
    
    margin-left: 6%;
    margin-right: 6%;

    margin-top: -40px;
}

.solutions-icon-container {
    height: 110px;
    width: 90px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.solutions-icon-container:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.solutions-icon-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #ACACAC;
    text-align: center;

    margin: 0;
    padding: 0;

    margin-top: 20px;
    margin-bottom: 10px;
}

.solutions-icon-image {
    height: 48px;

    margin: 0;
    padding: 0;

    margin-top: 14px;
}

.solutions-bottom-image-top-text{
    margin-top: 185px;
}

.solutions-bottom-image-title {
    margin-top: 19px;
}

.solutions-bottom-image-bottom-text {
    margin-top: 19px;
}

.solutions-compatability-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-left: 7.5%;
    margin-right: 7.5%;

    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.25);

    margin-bottom: 40px;
}

.solutions-compatability-image {
    width: 94%;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 27px;
}

.solutions-compatability-title {
    font-size: 28px;
    font-weight: 500;
    line-height: 42px;
    color: #000000;
    text-align: center;

    margin: 0;
    padding: 0;

    margin-top: 23px;
    margin-bottom: 20px;
}

@media screen and (min-width: 700px) {

    .solutions-top-image-top-text{
        margin-top: 132px;
        margin-left: 6%;
        margin-right: 6%;
    }
    
    .solutions-bottom-image-top-text {
        margin-top: 183px;
        margin-left: 6%;
        margin-right: 6%;
    }

    .solutions-icons-container-entire {
        height: 293px;
        margin-left: 6%;
        margin-right: 6%;
    }
    
    .solutions-icons-container {
        height: 293px;
        margin-left: 6%;
        margin-right: 6%;
        padding-top: 10px;
        padding-bottom: 10px;

        /* if there is more than 4 elements in a row make it to next row under the first row */
        flex-wrap: wrap;
    }

    .solutions-icon-container {
        flex: 0 0 17%;    
        height: 128px;
        width: 110px;
    }    

    .solutions-top-image-title, .solutions-bottom-image-title, .solutions-top-image-bottom-text, .solutions-bottom-image-bottom-text {
        margin-left: 6%;
        margin-right: 6%;
    }

    .solutions-compatability-container {
    
        margin-left: 6%;
        margin-right: 6%;
    
    
        margin-bottom: 80px;
    }
    
    .solutions-compatability-image {
        width: 88%;
        margin-left: 48px;
        margin-right: 48px;
        margin-bottom: 54px;
    }

    .solutions-compatability-title {
        font-size: 42px;
        line-height: 52px;
    
        margin-top: 72px;
        margin-bottom: 44px;
    }
    
}

@media screen and (min-width: 1000px) {
    .solutions-top-image-container, .solutions-bottom-image-container {
        height: 700px;
    }

    .solutions-top-image-top-text{
        margin-top: 242px;
        margin-left: 11%;
        margin-right: 11%;
    }
    
    .solutions-bottom-image-top-text {
        margin-top: 293px;
        margin-left: 11%;
        margin-right: 11%;
    }

    .solutions-icons-container-entire {
        height: 386px;
        margin-left: 11%;
        margin-right: 11%;
    }
    
    .solutions-icons-container {
        height: 370px;
        margin-left: 11%;
        margin-right: 11%;
        margin-top: -90px;
    }

    .solutions-icon-container {
        height: 160px;
        width: 125px;
    }    

    .solutions-top-image-title, .solutions-bottom-image-title, .solutions-top-image-bottom-text, .solutions-bottom-image-bottom-text {
        margin-left: 11%;
        margin-right: 11%;
    }

    .solutions-compatability-container {
    
        margin-left: 11%;
        margin-right: 11%;
    
    
        margin-bottom: 80px;
    }
    
    .solutions-compatability-image {
        width: 78%;
        margin-left: 150px;
        margin-right: 150px;
    }

    .solutions-icon-text {
        font-size: 18px;
    }

    .solutions-compatability-title {
        margin-bottom: 30px;
    }

    .solutions_stretched_container {
        height: 700px;
    }
}

@media screen and (min-width: 1367px) {
    .solutions-video-container {
        width: 100vw; /* Stretch container to the full viewport width */
  height: 100%; /* Set the desired height for the image */
  max-height: 1200px;
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  display: flex; /* Use flex to center the image inside the container */
  justify-content: center;
  align-items: center;
      }

      .solutions-compatability-container {
        width: 100vw; /* Stretch container to the full viewport width */
  height: 100%; /* Set the desired height for the image */
  overflow: hidden; /* Hide parts of the image that overflow the container */
  position: relative; /* Relative positioning for the container */
  margin-left: -50vw; /* Offset the container to the left */
  margin-right: -50vw; /* Offset the container to the right */
  display: flex; /* Use flex to center the image inside the container */
  justify-content: center;
  align-items: center;
      }

      .solutions-compatability-image {
        width: 1065px;
      }
}