.HomePage {
  position: relative;
  width: 100vw;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
}

.home-page-slider-images {

    padding-bottom: 54px;
    margin-top: 28px;
}

.home-page-slider-images img {
    border-radius: 10px;
    margin: 0;
    padding: 0;
    object-fit: cover;
}

.home-page-text-section {
    padding-left: 7.5%;
    padding-right: 7.5%;
    padding-top: 54px;
}

.home-page-text-title {
    font-size: 28px;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    margin-bottom: 28px;
}

.home-page-text-body {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    margin-bottom: 28px;
}

.home-page-button {
    align-self: baseline;
    margin-bottom: 54px;
}

.home-page-button button {
    border: 1px solid #ACACAC; /* Adjust to match your theme */
    color: #161616;
    background: transparent;
    cursor: pointer;
    border-radius: 10px; /* Rounded corners */
    transition: background-color 0.3s;

    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -1.1%;

    padding: 14px 23.2px;
}

.home-page-button button:hover {
    background-color: gray;
  }

  .home-page-full-width-img {
    width: 100%;
    height: 380px;
  }

  .home-page-phone-section-icons {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 16px;

    padding-left: 7.5%;
    padding-right: 7.5%;
  }

  .home-page-phone-section-icons-icon {
    width: 42px;
    height: 42px;
  }

  .home-page-phone-section-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    padding-left: 7.5%;
    padding-right: 7.5%;

    margin-bottom: 24px;
  }

  .home-page-phone-section-body {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    padding-left: 7.5%;
    padding-right: 7.5%;

    margin-bottom: 24px;
  }

  .home-page-phone-section-read-more {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    padding-left: 7.5%;
    padding-right: 7.5%;

    margin-bottom: 46px;

    text-decoration: underline;
    border: none;
    background: none;
    align-self: baseline;
  }

  .home-page-phone-section-img {
    width: 571px;
    height: 571px;
  }

  .home-page-phone-section {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    padding-top: 76px;
    padding-bottom: 54px;
  }

  .home-page-live-the-dream-section {
    padding-left: 7.5%;
    padding-right: 7.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
  }

  .home-page-live-the-dream-section-title {
    font-size: 28px;
    line-height: 52px;
    font-weight: 500;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    margin-bottom: 28px;
  }

  .home-page-live-the-dream-section-body {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1.1%;

    color: #000000;

    margin: 0;
    padding: 0;

    margin-bottom: 28px;
  }

  .home-page-live-the-dream-section-images {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .home-page-live-the-dream-section-images-img {
    width: 100%;
    height: 340px;
    object-fit: cover;
    border-radius: 10px;
  }

  .home-page-live-the-dream-section-images-img-container {
    position: relative;
  }

  .home-page-live-the-dream-section-images-img-text {
    max-width: 100px;
    font-size: 28px;
    line-height: 32px;
    font-weight: 500;
    letter-spacing: -1.1%;

    color: #FFFFFF;

    margin: 0;
    padding: 0;

    position: absolute;

    bottom: 20px;
    left: 20px;
  }

  .home-page-phone-section-img-container {
    width: 100%;
    height: 100%;

    overflow: hidden;
    justify-content: center;
    display: flex;
  }

  .home-page-experience-video-container {
    height: 340px;
    width:85%;
    margin-left: 7.5%;
    margin-right: 7.5%;
    border-radius: 30px;
    overflow: hidden; 
  }

  .home-page-experience-video {
  object-fit: cover; 
  width: 100%;
  height: 100%;
  /* center video in frame */
  object-position: center;   
  }


  .home-page-feel-the-dream {

  }

  .home-page-live-the-dream-section-title.bottom {
    max-width: 706px;
    margin-top: 54px;
    margin-bottom: 54px;
    line-height: 54px;
    align-self: center;
    text-align: center;
  }

  .home-page-experience-section {
    padding-bottom: 54px;
  }


@media screen and (min-width: 700px) {

    .home-page-slider-images {
    
        margin-top: 54px;
    }

    .home-page-text-section {
        padding-left: 6%;
        padding-right: 6%;
    }

    .home-page-text-title {
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 48px;
    }

    .home-page-text-body {
        font-size: 22px;
        line-height: 33px;
    
        margin-bottom: 48px;
    }


    .home-page-phone-section-icons {
        padding-left: 6%;
        padding-right: 6%;
    }

  .home-page-phone-section-icons-icon {
    width: 85px;
    height: 85px;
  }

  .home-page-phone-section-title {
    font-size: 42px;
    line-height: 52px;

    padding-left: 6%;
    padding-right: 6%;
  }

  .home-page-phone-section-body {
    font-size: 22px;
    line-height: 33px;

    padding-left: 6%;
    padding-right: 6%;
  }

  .home-page-phone-section-read-more {
    font-size: 18px;
    line-height: 27px;

    padding-left: 6%;
    padding-right: 6%;

    margin-bottom: 0px;
  }

  .home-page-phone-section-img {
    width: 571px;
    height: 571px;
    margin-top: -24px;
    margin-right: 6%;
  }

  .home-page-phone-section {
    padding-top: 87px;
  }

  .home-page-phone-section-img-container {
    overflow: visible;
    text-align: right;
    justify-content: flex-end;
  }

  .home-page-live-the-dream-section {
    padding-left: 6%;
    padding-right: 6%;
  }

  .home-page-live-the-dream-section-title {
    font-size: 42px;
  }

  .home-page-live-the-dream-section-body {
    font-size: 22px;
    line-height: 33px;

    margin-bottom: 72px;
  }

  .home-page-live-the-dream-section-images-img {
    height: 400px;
  }

  .home-page-live-the-dream-section-images {
    gap: 22px;
  }

  .home-page-live-the-dream-section-images-img-text {
    max-width: 100px;
    font-size: 42px;
    line-height: 52px;

    bottom: 42px;
    left: 42px;
  }

  .home-page-experience-video-container {
    width: 88%;
    margin-left: 6%;
    margin-right: 6%;
    height: 450px;
  }
}

@media screen and (min-width: 1000px) {

    .home-page-text-section {
        padding-left: 11%;
        padding-right: 11%;
    }

    .home-page-text-body {
        margin-bottom: 28px;
    }

    .home-page-phone-section-icons-icon {
        width: 85px;
        height: 85px;
      }

      .home-page-phone-section-icons {
        padding-left: 0%;
        padding-right: 0%;
      }
    
      .home-page-phone-section-title, .home-page-phone-section-body, .home-page-phone-section-read-more {    
        padding-left: 0%;
        padding-right: 0%;
      }

      .home-page-phone-section-img {
        width: 857px;
        height: 857px;
        margin: 0;
        padding: 0;
      }
    
      .home-page-phone-section {
        padding-top: 54px;
        flex-direction: row;
      }
    
      .home-page-phone-section-img-container {
        width: 100%;
    
        overflow: hidden;
    
        justify-content: left;
        align-items: right;
      }

      .home-page-phone-section-without-phone {
        margin-top: 252px;
        padding-left: 11%;
      }

      .home-page-live-the-dream-section {
        padding-left: 11%;
        padding-right: 11%;
      }
    
      .home-page-live-the-dream-section-body {
        margin-bottom: 54px;
        max-width: 685px;
      }
    
      .home-page-live-the-dream-section-images {
        flex-direction: row;
        gap: 11px;
      }

      .home-page-experience-section {
        max-width: 1366px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 67px;
        padding-top: 110px;
        padding-bottom: 110px;
      }

      .home-page-dream-section {
        max-width: 1366px;
        margin: 0 auto;
      }

      .home-page-experience-section .home-page-text-section {
        padding-left: 11%;
        padding-right: 0%;
      }

      .home-page-experience-video-container {
        margin-right: 11%;
        margin-left: 0%;
        width: 120%;
        height: 400px;
      }
}

@media screen and (min-width: 1367px) {
  .home-page-text-section {
    max-width: 1366px;
    margin: 0 auto;
  }

  .home-page-text-title {
    padding-left: 11%;
    padding-right: 11%;
  }

  .home-page-text-body {
    padding-left: 11%;
    padding-right: 11%;
  }

  .contactus_visit_us_button {
    padding-left: 11%;
    padding-right: 11%;

  }

  .home-page-slider-images {
    max-width: 1366px;
    margin: 0 auto;
  }

  .home-page-slider-image {
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-page-live-the-dream-section {
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-page-live-the-dream-section-title {
    padding-left: 11%;
    padding-right: 11%;
  }

  .home-page-live-the-dream-section-body {
    padding-left: 11%;
    padding-right: 11%;
  }

  .home-page-live-the-dream-section-images {
    padding-left: 11%;
    padding-right: 11%;
  }

  .home-page-phone-section {
    max-width: 1366px;
    margin: 0 auto;
    padding-left: 0%;
    padding-right: 0%;
  }

  .home-page-max-width-sections {
    max-width: 1366px;
    margin: 0 auto;
  }

  .padding-remover {
    padding-left: 0;
  }

  .home-page-phone-section-img-container {
    overflow: visible;
  }

}
