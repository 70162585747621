.navbar-container {
  max-width: 1366px;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 7.5%;
  padding-right: 7.5%;
  background-color: #000000;
  box-shadow: 0 0 0 100vmax #000000;
  clip-path: inset(0 -100vmax); 

  position: fixed;
  width: 85%;
  top: 0;
  z-index: 10;
}

.logo-img {
  height: 26px;
}

.navbar-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 27px;
}

.hamburger-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background-color: #000000;
}

.navbar-button-border {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1.1%;
  color: #161616;
  text-align: center;
}

.navbar-button-borderless {
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1.1%;
  color: #ffffff;
  text-align: center;
}

.navbar-button-products {
  border: none;
  background: transparent;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}

.navbar-hamburger-icon-button {
  background: none;
  border: none;
  cursor: pointer;
}

.navbar-links-mobile {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  position:fixed;
  top: 106px;
  right: 50px;
  z-index: 1;  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-products-tab {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 0px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 4px;

  text-align: left;
}

.navbar-products-button {
  border: none;
  background-color: #ffffff;
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5px;
  padding-right: 8px;
  text-decoration: none;

  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;
  color: #000000;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.navbar-products-title {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -1.1%;
  color: #787878;
  text-align: left;
  margin: 0;
  padding: 0;
}

.navbar-products-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 3;
}

.navbar-icon-container {
  margin:0;
  padding:0;
  height: 14px;
  align-self: center;
  border-radius: 10px;
  background-color: #D9D9D9;
  padding: 8px;
}

.navbar-icon-container > img {
  height: 14px;
  width: 14px;
}

@media screen and (min-width: 700px) {
  .navbar-container {
    width: 88%;
    padding-left: 6%;
    padding-right: 6%;
  }

  .navbar-links-mobile {
    flex-direction: row;
  }
}

@media screen and (min-width: 1000px) {
  .navbar-container {
    width: 78%;
    padding-left: 11%;
    padding-right: 11%;
  }

  .navbar-links-mobile{
    margin-right: 11%;
    right: 120px;
  }
}

@media screen and (min-width: 1350px) {
  .navbar-container {
    padding-left: 150px;
    width: 1066px;
  }

  .navbar-links-mobile{
    right: auto;
    margin-left: 930px;
  }
}