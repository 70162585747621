body {
  background-color: #ffffff;
  min-width: 400px;
  max-width: 1366px;
  margin: 0 auto;
  float: none;

  padding-top: 88px;
}

* {
  font-family: 'Poppins', sans-serif;
}
