.google-maps-top-text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;
    color: #161616;
}

.google-maps-bottom-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-align: left;
    color: #787878;
}

.google-maps-text-container {
    padding: 0 20px;
    background-color: #ffffff;
    border-radius: 10px;

    position: absolute;
    bottom: 20px;
    left: 10px;
    right: 65px;
}
